import React, { memo } from "react";

import "../styles/portfolio.scss";
import paint from "../images/Screenshot 2024-01-13 at 4.59.12 PM.png";
import screen from "../images/Screenshot 2024-01-13 at 4.53.25 PM.png";
import buzz from "../images/gallery.jpg";
import raylib from "../images/trim.gif";
import pilot from "../images/pilot.png";
import terminal from "../images/Screenshot 2024-01-13 at 5.32.39 PM.png";
import pederson from "../images/peders (1).png"
import report from './main.pdf';
import dns from "../images/Networks_Final_Writeup.pdf";
import barnacle from "../images/barnacle.png";

import dns_image from "../images/dns_resolve.png";
import tcp_image from "../images/tcp_ladder_states.png";
import splash from "../images/splash.png";


const Portfolio = memo(({ refs }) => {
  return (
    <section ref={refs} className="portfolio-section">
      <div className="section-title">PORTFOLIO</div>
      <div className="portfolio-content">
      <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src= "/static/media/peders (1).ac5e72d6.png" alt="pederson"/>
            </div>
              <br />
            <div className="title">Zero-Knowledge Proof for Graph 3-Coloring</div>
              <br />
            <div className="contents">
              This project was the term project for CS1515, Applied Cryptography, at Brown University. I worked with <a href="https://edwardwibowo.com/">Edward Wibowo</a> on this project, implementing a zero-knowledge proof protocol in Go for graph 3-coloring using a server and client for the verifier and prover. Mapped each of the three colors to another distinct color to ensure the verifier cannot learn the true colors of the graph. Each round, the colors were randomly shuffled, preventing the verifier from learning from previous rounds.
Some benchmarks were conducted to analyze the efficiency of the protocol: 1,000,000 repetitions takes 41.509 seconds on average to complete.
            </div>
            <a
              className="button"
              href="https://github.com/claby2/3color-zkp"
            >
              github link
            </a>
            <a
              className="button"
              href="https://youtu.be/PNrXs2Wu73E"
            >
              video demo
            </a>
            <a
              className="button"
              href= {report}
            >
              final report
            </a>

          </div>
        </div>
      <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src= "static/media/tcp_ladder_states.3b04cc69.png" alt="pilot"/>
            </div>
              <br />
            <div className="title">IP/TCP</div>
              <br />
            <div className="contents">
            Implemented the TCP/IP protocol from scratch in Go following RFC standards. Developed the IP stack network layer with packet forwarding, RIP routing, and route finding. Integrated the TCP stack with the sliding window protocol, out-of-order packet handling, and packet retransmission. Implemented zero window probing and verified TCP protocol correctness through Wireshark packet analysis.
            </div>
            <a
              className="button"
              href="https://github.com/Timfon/IP-TCP"
            >
              github link
            </a>
          </div>
        </div>

      <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src= "/static/media/dns_resolve.a9aa4df0.png" alt="pilot"/>
            </div>
              <br />
            <div className="title">DNS resolver</div>
              <br />
            <div className="contents">
            The DNS Query Tool is a Python-based utility that enables users to perform various types of DNS queries, including recursive, iterative, and single-step queries. This tool offers more verbose output, making it easier to analyze DNS resolutions in detail.

Key features include support for full recursive DNS queries starting from root servers and full iterative queries that display the step-by-step resolution process. Users can also perform single-step queries to specific nameservers. The tool comes with a built-in list of root DNS servers, provides detailed output that includes the answer, authority, and additional sections, and measures query times. Additionally, it supports custom nameserver specification for enhanced flexibility.
            </div>
            <a
              className="button"
              href="https://github.com/Timfon/DNS-resolver"
            >
              github link
            </a>
            <a
              className="button"
              href="https://youtu.be/JK-kTbiX-zs"
            >
              video demo
            </a>
            <a
              className="button"
              href= {dns}
            >
              final report
            </a>
          </div>
        </div>

        
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src="/static/media/pilot.d8f47e79.png" alt="pilot"/>
            </div>
              <br />
            <div className="title">Paper Pilot</div>
              <br />
            <div className="contents">
              Paper Pilot is a webapp where users can upload a DOI link of their choice, and the application will recommend papers similar to that DOI link. 
              It utilizes React Routing to navigate between pages, Python for backend, typescript, Google's firebase to store the user's bookmarked papers, a DocToVec model that converts each paper into a vector, and a kd-tree algorithm for the recommendation algorithm. 

            </div>
            <a
              className="button"
              href="https://github.com/cs0320-f23/term-project-tfong1-ryhuang-dhan25-eko10"
            >
              github link
            </a>
          </div>
        </div>
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img
                src= "/static/media/trim.0a89d5bb.gif"
                alt="raylib"
              />
            </div>
            <br />
            <div className="title">Raylib Python C Foreign Function Interface</div>
            <div className="contents">
              <br />
              Contributed to the popular open source graphics library Raylib by translating code examples from C to Python.
Implemented 6 examples from the “core” and “models” modules: split screen, vr simulator, pixel perfect camera, window flags, waving cubes, and 3D free camera.
            </div>
            <a
              className="button"
              href="https://github.com/electronstudio/raylib-python-cffi"
            >
              github link
            </a>

          </div>
        </div>
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img
                src="/static/media/Screenshot 2024-01-13 at 4.53.25 PM.420f10a9.png"
                alt="maps"
              />
            </div>
            <br />
            <div className="title">Maps</div>
            <div className="contents">
              <br />
              Maps is an interactive webapp that allows users to look at historical redlining data on a map through visualizations. Users can click anywhere on the map and get broadband access data by county and state. Users can also use the search bar to search for terms in the area description data of the file.
            </div>
            <a className="button" href="https://github.com/cs0320-f23/maps-dhan25-tfong1">

              github link
            </a>
          </div>
        </div>
        
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src= "/static/media/Screenshot 2024-01-13 at 5.32.39 PM.bae7a97f.png"
                   alt="terminal"/>
            </div>
            <br />
            <div className="title">Shell</div>
            <div className="contents">
              <br />
              Created a shell in C from scratch. The shell can parse user input, handle signals, reap foreground and background processes, as well as redirect standard output/input using redirection symbols: "{">"}", "{">>"}", "{"<"}".
            </div>
            <a
              className="button"
              href="https://github.com/cs0330-fall2023/5-shell-2-Timfon"
            >
              github link
            </a>

          </div>
        </div>
    <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src= "/static/media/splash.acf9c4f0.png"
                   alt="splash"/>
            </div>
            <br />
            <div className="title">MacOS Rice</div>
            <div className="contents">
              <br />
              Customized my MacOS desktop using the kitty for terminal, yabai for window management, and skhd for keybindings. Additionally, I used the zsh shell with the powerlevel10k theme, as well as using the neovide graphical user interface.
              For the status bar, I used sketchy bar, and for the borders, I used JankyBorders by Felix Kratz. Feel free to checkout my dotfiles below!
            </div>
            <a
              className="button"
              href="https://github.com/Timfon/dotfiles-timfon.git">
              github link
            </a>

          </div>
        </div>

        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src= "/static/media/barnacle.1432d30c.png"
                   alt="barnacle"/>
            </div>
            <br />
            <div className="title">Barnacle Image Analysis Automation</div>
            <div className="contents">
              <br />
Programmed an automation method for analyzing calcite crystal area for barnacles submerged in pH 7.50-8.01 seawater using Fiji for image analysis and Weka machine learning tool for image segmentation.
Implemented Beanshell scripts into the automation method to allow the model to be trained on 33+ SEM images.
            </div>
            <a
              className="button"
              href="https://drive.google.com/file/d/10YRMaeqcc_6pmG2XUPckuw71A5xDz-5I/view"
            >
              Research Abstract
            </a>
          </div>
        </div>
      </div>

    </section>
  );
});
export default Portfolio;
