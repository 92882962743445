import React, { memo } from "react";

import "../styles/skills.scss";

import {
  ReactJs,
  Redux,
  Reduxsaga,
  Sass,
  Javascript,
  Html5,
  CssThree,
  Nodedotjs,
  Express,
  Mysql,
  R,
  Mongodb,
  Typescript,
  Tailwindcss,
  Flask,
  Python,
  Java,
  Csharp,
  C,
  Cplusplus,
  Go,
  Git,
  Docker,
  Linux,
  Vim,
  Latex,
  Tensorflow,
} from "@icons-pack/react-simple-icons";

const Skills = memo(({ refs }) => {
  return (
    <section ref={refs} className="skills-section">
      <div className="section-title">SKILLS</div>
      <div className="skills-content">
        <h3 className="skill-title">frontend</h3>
        <div className="skills-infos">
          <div className="skill-group">
            <div className="skill">
              <Html5 color="#E34F26" size={50} />
            </div>
            <p className="skill-info">HTML</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <CssThree color="#1572B6" size={50} />
            </div>
            <p className="skill-info">CSS</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Javascript color="#F7DF1E" size={50} />
            </div>
            <p className="skill-info">JS</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <ReactJs color="#61DAFB" size={50} />
            </div>
            <p className="skill-info">React.js</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Typescript color="#1572B6" size={50} />
            </div>
            <p className="skill-info">TypeScript</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Tailwindcss color="#61DAFB" size={50} />
            </div>
            <p className="skill-info">TailwindCSS</p>
          </div>
        </div>

        <h3 className="skill-title">backend</h3>
        <div className="skills-infos">
          <div className="skill-group">
            <div className="skill">
              <Nodedotjs color="#339933" size={50} />
            </div>
            <p className="skill-info">Node.js</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Flask color="#000000" size={50} />
            </div>
            <p className="skill-info">Flask</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Mysql color="#000000" size={50} />
            </div>
            <p className="skill-info">MySQL</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Tensorflow color="#FF6F00" size={50} />
            </div>
            <p className="skill-info">TensorFlow</p>
          </div>
        </div>

        <h3 className="skill-title">languages</h3>
        <div className="skills-infos">
          <div className="skill-group">
            <div className="skill">
              <Python color="#276DC3" size={50} />
            </div>
            <p className="skill-info">Python</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Go color="#276DC3" size={50} />
            </div>
            <p className="skill-info">Go</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <C color="#4479A1" size={50} />
            </div>
            <p className="skill-info">C</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Cplusplus color="#4479A1" size={50} />
            </div>
            <p className="skill-info">C++</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Csharp color="#A020F0" size={50} />
            </div>
            <p className="skill-info">C#</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Java color="#000000" size={50} />
            </div>
            <p className="skill-info">Java</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <span className="text-2xl font-bold">🐫</span>
            </div>
            <p className="skill-info">OCaml</p>
          </div>
        </div>

        <h3 className="skill-title">technologies</h3>
        <div className="skills-infos">
          <div className="skill-group">
            <div className="skill">
              <Git color="#F05032" size={50} />
            </div>
            <p className="skill-info">Git</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Docker color="#2496ED" size={50} />
            </div>
            <p className="skill-info">Docker</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Linux color="#FCC624" size={50} />
            </div>
            <p className="skill-info">Linux</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Vim color="#019733" size={50} />
            </div>
            <p className="skill-info">Neovim</p>
          </div>
          <div className="skill-group">
            <div className="skill">
              <Latex color="#008080" size={50} />
            </div>
            <p className="skill-info">LaTeX</p>
          </div>
        </div>

      </div>
    </section>
  );
});

export default Skills;